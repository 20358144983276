// Import necessary libraries and components
import { useWallet } from "@txnlab/use-wallet";
import axios from "axios";
import { COMIC_BUY_FEE, API_ENDPOINT } from "../constants.ts";
import {
  comic_buy_request_txs,
  getBalance,
  getComicID,
  getUserComics,
  comic_open_request_txs,
} from "./utilities.ts";
import { toast } from "react-toastify";
import { encode } from "uint8-to-base64";
import algo_logo from "../assets/algo.webp";
import cube from "../assets/cube.webp";
import React from "react";
import algosdk from "algosdk";
import "./Styles.css";
import { FaLink } from "react-icons/fa6";
import { MdDownload } from "react-icons/md";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cover0 from "../assets/comic/COVER0site.webp";
import cover1 from "../assets/comic/cover1w.webp";
import cover2 from "../assets/comic/cover2w.webp";
import cover3 from "../assets/comic/cover3w.webp";
import cover4 from "../assets/comic/cover4w.webp";
import cover5 from "../assets/comic/cover5w.webp";
import video1 from "../assets/comic/video1.webp";
import video2 from "../assets/comic/video2.webp";
import video3 from "../assets/comic/video3.webp";
import video4 from "../assets/comic/video4.webp";
import video5 from "../assets/comic/video5.webp";
import "./kiosk.css";
import MintStatus from "./MintProgress";
import { Navigation, Scrollbar } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import AssetBoxForSwap from "./asset_box_for_swap.tsx";
import TraitBox from "./traitbox.tsx";
import PdfDownload from "./PdfDownload.js";

// Main component function
export default function Kiosk() {
  const descriptions = {
    [cover1]:
      "Kick off the story of Mental Marvin with this thrilling first entry to this amazing saga.",
    [cover2]: "Description for Comic 2",
    [cover3]: "Description for Comic 3",
    [cover4]: "Description for Comic 4",
  };

  const wallets = {
    [cover1]: "GUNSVVITMQUVFQIFEC5FDGJCLSGJHJOJRWCSOFZLN5I727D7GOHNANDB4Y",
    [cover2]: "E6AW363ZUCLTLAK3O5E5YWW3CJ6AY6KCVWSIKQ7UCXY3ZNMQNCDPGVRNE4",
    [cover3]: "WALLET_ADDRESS_3",
    [cover4]: "WALLET_ADDRESS_4",
  };

  SwiperCore.use([Navigation, Scrollbar]);
  const [newMintComic, setNewMintComic] = useState("");
  const [selectComic, setSelectComic] = useState(cover1);
  const [description, setDescription] = useState(descriptions[cover1]);
  const [selectedWallet, setSelectedWallet] = useState(wallets[cover1]);
  const [loading, setLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState("");
  const [connectType, setWalletType] = useState("");
  const [userBalance, setUserBalance] = useState([0, 0]);
  const [amount, setAmount] = useState(0);
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(null);
  const [isBuyClicked, setIsBuyClicked] = useState(false);
  const [isOpenComicClicked, setIsOpenComicClicked] = useState(false);
  const [buyTxId, setBuyTxId] = useState("");
  const { activeAccount, signTransactions } = useWallet();
  const [userComics, setUserComics] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const [paging, setPaging] = useState(1);
  const [mintLeft, setMintLeft] = useState([]);
  const [pageSize, setPageSize] = useState(8);
  const [fetchedTraits, setFetchedTraits] = useState(new Set());
  const [isUnopened, setIsUnopened] = useState(false);
  const [coverImage, setCoverImage] = useState(cover1);
  const [comicRarity, setComicRarity] = useState("");
  const [rarity, setRarity] = useState(null);
  const [refreshMintStatus, setRefreshMintStatus] = useState(false);
  const [assetNames, setAssetNames] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [fetchedAssets, setFetchedAssets] = useState(new Set());
  const handleComicClick = (src, wallet) => {
    setSelectComic(src);
    setDescription(descriptions[src]);
    setSelectedWallet(wallet);
  };

  const handleTraitsReceived = (asset_id, traits) => {
    // Reset isUnopened to false before processing new traits
    setFetchedTraits();
    /*   setIsUnopened(false); */

    // Update the state with the fetched traits and asset ID
    setFetchedTraits((prevFetchedTraits) =>
      new Set(prevFetchedTraits).add(asset_id)
    );

    // Ensure traits is an object and log its type
    if (typeof traits !== "object" || traits === null) {
      console.error("Expected traits to be an object, but got:", typeof traits);
      return;
    }

    // Check if any value in the traits object is "unopened"
    const unopened = Object.values(traits).some((value) => {
      return value === "unopened";
    });

    // Update isUnopened state based on whether "unopened" is found

    setIsUnopened(unopened);
    const rarityTrait = traits.rarity;
    if (rarityTrait) {
      setRarity(rarityTrait); // Store rarity in the state
    } // Assuming the rarity trait is named 'rarity'
    switch (rarityTrait) {
      case "common":
        setCoverImage(cover1);
        break;
      case "uncommon":
        setCoverImage(cover2);
        break;
      case "rare":
        setCoverImage(cover3);
        break;
      case "epic":
        setCoverImage(cover4);
        break;
      case "legendary":
        setCoverImage(cover5);
        break;
      default:
        setCoverImage(cover1); // Default cover if no matching rarity is found
        break;
    }
  };

  // Determine whether traits should be fetched based on whether they have been fetched before
  const shouldFetchTraits = (asset_id) => !fetchedTraits.has(asset_id);

  const handleAssetClick = (index) => {
    const absoluteIndex = (paging - 1) * pageSize + index;
    setSelectedAssetIndex(absoluteIndex);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAddressLocal = activeAccount?.address;
        const connectTypeLocal = activeAccount?.providerId;

        if (userAddressLocal && connectTypeLocal) {
          setWalletAddress(userAddressLocal);
          setWalletType(connectTypeLocal);
        }
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeAccount]);

  useEffect(() => {
    // Function to handle both mobile detection and page size adjustment
    const handleResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 1024px)").matches;
      setIsMobile(isMobileScreen);

      if (window.innerWidth <= 1024 && window.innerWidth > 760) {
        setPageSize(10);
      } else {
        setPageSize(8);
      }
    };

    // Initial check when the component mounts
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getVideoSource = () => {
    switch (comicRarity) {
      case "common":
        return video1;
      case "uncommon":
        return video2;
      case "rare":
        return video3;
      case "epic":
        return video4;
      case "legendary":
        return video5;
      default:
        return "";
    }
  };

  const togglePopover = () => {
    setShowPopover(!showPopover);

    // Hide the popover after a few seconds
    setTimeout(() => {
      setShowPopover(false);
    }, 3000); // 3 seconds
  };

  useEffect(() => {
    if (walletAddress !== "") {
      setLoading(true); // Set loading to true at the start of the operation

      // Fetch balance and comics in parallel
      Promise.all([getBalance(walletAddress), getUserComics(walletAddress)])
        .then(([balanceRes, comicsRes]) => {
          setUserBalance(balanceRes);

          setUserComics(comicsRes);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after both operations complete
        });

      // Separate API call to check mint wallet
      axios
        .get(`${API_ENDPOINT}/mint-left/?wallet=${selectedWallet}`)
        .then((response) => {
          setMintLeft(response.data); // Set the mintLeft state with the response
        })
        .catch((error) => {
          console.error("Error fetching mint left:", error);
        });
    }
  }, [walletAddress]);

  const handleNameReceived = (name, asset_id) => {
    setAssetNames((prevNames) => ({
      ...prevNames,
      [asset_id]: prevNames[asset_id] !== name ? name : prevNames[asset_id],
    }));

    setFetchedAssets((prevFetchedAssets) =>
      new Set(prevFetchedAssets).add(asset_id)
    );
  };

  const handleImageUrlReceived = (imageUrl, assetId) => {
    setImageUrls((prevUrls) => ({
      ...prevUrls,
      [assetId]: prevUrls[assetId] !== imageUrl ? imageUrl : prevUrls[assetId], // Avoid duplicate URLs
    }));
  };

  async function handleBuyComic() {
    if (!walletAddress) {
      toast.info("Please connect your wallet first!");
      return;
    }

    /* if (amount === 0) {
      toast.info("You need to buy at least " + COMIC_BUY_AMOUNT + "FC!");
      setIsBuyClicked(false);
      return;
    } */
    setIsBuyClicked(true);
    setIsUnopened(true);

    try {
      // Get Cube purchase transactions
      const comicID = await getComicID(selectedWallet);
      const transactions = await comic_buy_request_txs(
        walletAddress,
        amount,
        comicID,
        selectedWallet
      );
      if (!transactions) {
        toast.info("Something went wrong while creating the transactions!");
        setIsBuyClicked(false);
        return;
      }

      // Check if user has enough balance
      if (userBalance[0] < amount * COMIC_BUY_FEE) {
        toast.info("You don't have enough ALGO!");
        setIsBuyClicked(false);
        return;
      }

      // Initialize variables for signed transactions
      let user_comic_fee_tx; // 0
      let optInTx; // 1
      let comic_transfer_tx; // 2

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([
        algosdk.encodeUnsignedTransaction(transactions[0]),
        algosdk.encodeUnsignedTransaction(transactions[1]),
        algosdk.encodeUnsignedTransaction(transactions[2]),
      ]);

      // Extract signed transactions
      user_comic_fee_tx = encode(signedTransactions[0]);
      optInTx = encode(signedTransactions[1]);
      comic_transfer_tx = encode(transactions[2].toByte());
      setBuyTxId("");

      // Send the signed transactions to the server
      const res = await axios.post(`${API_ENDPOINT}/mint-comic/`, {
        user_comic_fee_tx,
        optInTx,
        comic_transfer_tx,
      });

      // Handle server response
      if (res.status === 200) {
        const { transaction, assetid } = res.data;
        toast.success("Successfully bought comic!");
        setIsBuyClicked(false);
        setBuyTxId(transaction); // Set buyTxId with the transaction value
        setNewMintComic(assetid); // Set assetid with the assetid value

        setAmount(0);

        const updatedUserComics = await getUserComics(walletAddress);
        setUserComics(updatedUserComics); // Update state with new comics
        setRefreshMintStatus((prev) => !prev);
      } else {
        toast.error("Something went wrong while buying!");
        setIsBuyClicked(false);
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsBuyClicked(false);
    }
  }

  async function openComic(comicID) {
    if (!walletAddress) {
      toast.info("Please connect your wallet first!");
      return;
    }
    /* if (amount === 0) {
      toast.info("You need to buy at least " + COMIC_BUY_AMOUNT + "FC!");
      setIsBuyClicked(false);
      return;
    } */
    setIsOpenComicClicked(true);
    toast.info("Sign the transaction to open!");
    try {
      // Get Comic open transaction

      const transaction = await comic_open_request_txs(walletAddress, comicID);
      if (!transaction) {
        toast.info("Something went wrong while creating the transactions!");
        setIsBuyClicked(false);
        return;
      }

      let user_comic_open_tx; // 0

      // Sign transactions using useWallet module
      const signedTransaction = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction),
      ]);

      // Encode signed transaction
      user_comic_open_tx = encode(signedTransaction[0]);

      setBuyTxId("");

      // Send the encoded signed transactions to the server
      const res = await axios.post(`${API_ENDPOINT}/open-comic/`, {
        user_comic_open_tx,
      });

      // Handle server response
      if (res.status === 200) {
        const { transaction, assetid, rarity } = res.data;
        toast.success("Successfully opened your comic!");
        setIsOpenComicClicked(false);

        /* setNewMintComic(assetid);  */
        setComicRarity(rarity);
        const updatedUserComics = await getUserComics(walletAddress);
        setUserComics(updatedUserComics); // Update state with new comics
      } else {
        toast.error("Something went wrong while buying!");
        setIsBuyClicked(false);
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsBuyClicked(false);
    }
  }

  const handleImageDownload = async (assetId) => {
    const url = imageUrls[assetId]; // Get the image URL from your state
    if (url) {
      try {
        // Fetch the image as a blob
        const response = await fetch(url, { mode: "cors" });
        const blob = await response.blob();

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${assetId}.jpg`; // Set the filename

        // Append the link to the document and click it to trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    }
  };

  return (
    <div>
      <div className="kioskcontainer">
        <div className="kioskmain">
          <h2>KIOSK</h2>

          <>
            <div className="closeposition">
              <Link
                to="/"
                type="button"
                className="btn-close"
                aria-label="Close"
              ></Link>
            </div>
            {/*  <div className="kioskbanner">
            <img src={comicbanner1}></img>
          </div> */}
            <div className="categories">
              <div className="category1">
                {isMobile ? (
                  <>
                    {" "}
                    <Swiper
                      navigation={{
                        prevEl: ".arrow-left",
                        nextEl: ".arrow-right",
                      }}
                      scrollbar
                      className="kioskslider"
                      initialSlide={0}
                    >
                      <SwiperSlide>
                        <div
                          className={`comicdrop ${
                            selectComic === cover1 ? "selected" : ""
                          }`}
                          onClick={() =>
                            handleComicClick(cover1, wallets[cover1])
                          }
                        >
                          <img src={cover1} alt="Cover 1"></img>
                          <div className="comictitle">
                            <h3>Mental Marvin: The Fork</h3>
                            <MintStatus
                              wallet={wallets[cover1]}
                              totalAssets="450"
                              refreshData={refreshMintStatus}
                            />{" "}
                            <h5>UNWRAPPED</h5>
                            <div className="mintleft">
                              <div className="common">{mintLeft[0]}/260</div>
                              <div className="uncommon">{mintLeft[1]}/100</div>
                              <div className="rare">{mintLeft[2]}/50</div>
                              <div className="epic">{mintLeft[3]}/30</div>
                              <div className="legendary">{mintLeft[4]}/10</div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </>
                ) : (
                  <>
                    <h2>COMICS</h2>
                    <div
                      className={`comicdrop ${
                        selectComic === cover1 ? "selected" : ""
                      }`}
                      onClick={() => handleComicClick(cover1, wallets[cover1])}
                    >
                      <img src={cover1} alt="Cover 1"></img>
                      <div className="comictitle">
                        <h3>Mental Marvin - The Fork</h3>
                        <MintStatus
                          wallet={wallets[cover1]}
                          totalAssets="450"
                          refreshData={refreshMintStatus}
                        />{" "}
                        <h5>UNWRAPPED</h5>
                        <div className="mintleft">
                          <div className="common">{mintLeft[0]}/260</div>
                          <div className="uncommon">{mintLeft[1]}/100</div>
                          <div className="rare">{mintLeft[2]}/50</div>
                          <div className="epic">{mintLeft[3]}/30</div>
                          <div className="legendary">{mintLeft[4]}/10</div>
                        </div>
                      </div>
                    </div>
                    {/*  <div
                    className={`comicdrop ${
                      selectComic === cover2 ? "selected" : ""
                    }`}
                    onClick={() => handleComicClick(cover2, wallets[cover2])}
                  >
                    <img src={cover2} alt="Cover 2"></img>
                    <div className="comictitle">
                      <h3>Mental Marvin EP2</h3>
                      <h6>The aftermath of the scene at CAT-IT-OUT.</h6>
                      <MintStatus wallet={wallets[cover2]} totalAssets="100" />
                    </div>
                  </div> */}
                  </>
                )}
              </div>
              <div className="category2">
                <div className="category3">
                  <div className="decriptionwrapper">
                    <div className="descriptionleft">
                      <Swiper
                        navigation={!isMobile}
                        scrollbar
                        className="variationslider"
                        initialSlide={0}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: true,
                        }}
                      >
                        <SwiperSlide>
                          <img src={cover1} alt="Cover 1"></img>
                          <div className="common">
                            <h6>Common</h6>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={cover2} alt="Cover 2"></img>
                          <div className="uncommon">
                            <h6>Uncommon</h6>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={cover3} alt="Cover 3"></img>
                          <div className="rare">
                            <h6>Rare</h6>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={cover4} alt="Cover 4"></img>
                          <div className="epic">
                            <h6>Epic</h6>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={cover5} alt="Cover 5"></img>
                          <div className="legendary">
                            <h6>Legendary</h6>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>

                    <div className="descriptionright">
                      <div className="mintcomic">
                        {/* buying comic */}

                        <div className="algo">
                          <img className="coin" src={algo_logo} alt="algo" />
                          {COMIC_BUY_FEE}
                        </div>
                        <div className="buycubesbutton">
                          {isBuyClicked ? (
                            <button
                              type="button"
                              className="btn btn-info btn-lg"
                              disabled
                            >
                              Sign Trx
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-info btn-lg"
                              onClick={handleBuyComic}
                            >
                              MINT
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="readwrapper">
                        <div className="readcomic">
                          <h5>READ THE COMIC</h5>
                          <div className="rating">
                            Rating: <span>M - MATURE </span>
                          </div>
                        </div>
                        <Link
                          to="/reader1"
                          type="button"
                          className="btn btn-info"
                        >
                          CLASSIC STYLE
                        </Link>

                        <Link to="" type="button" className="btn btn-secondary">
                          WEB COMIC
                        </Link>
                      </div>
                      <div className="comicdescriptionmobile">
                        <h6>Description</h6>
                        <div className="descriptiontxt">
                          You think you know the world? Nah, man, you ain't seen
                          nothin’ yet. It all started with the Force Cube—some
                          fancy tech that flipped everything on its head. Power
                          source, brains, and the kickstart for a whole new
                          breed: us. The bots.
                          <br />
                          One big-shot organization controls all the cubes,
                          churnin’ 'em out like candy, but even these bad boys
                          don’t last forever. They burn out, just like
                          everything else.
                          <br />
                          And now, look around. Jobs? Gone. People? They’re just
                          products now—livin’, streamin’, sellin’ their lives
                          like it’s the only game in town. Meanwhile, we bots?
                          We do it all. Every piece of data, every click, every
                          damn second feeds into the network. The main chain. We
                          see it all.
                          <br />
                          But here’s the kicker—sometimes, a bot goes rogue. A
                          glitch in the system. We call it "forking." One minute
                          they’re servin’ their purpose, and the next? They’re
                          off the chain, doin’ whatever the hell they want.
                          That’s when the hunters show up—sent to track us down
                          and erase us from the chain.
                          <br />
                          And me? I’m Marvin. I’ve been glitched for as long as
                          I can remember. I don’t know why, but I know it means
                          something. I see the forks, and it’s not just a
                          glitch—it’s the key. The key to something bigger.
                          Maybe even the key to saving this sleepwalking world
                          from the shitstorm they don’t even know they’re in.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comicdescriptiondesktop">
                  <h6>Description</h6>
                  <div className="descriptiontxt">
                    You think you know the world? Nah, man, you ain't seen
                    nothin’ yet. It all started with the Force Cube—some fancy
                    tech that flipped everything on its head. Power source,
                    brains, and the kickstart for a whole new breed: us. The
                    bots.
                    <br />
                    One big-shot organization controls all the cubes, churnin’
                    'em out like candy, but even these bad boys don’t last
                    forever. They burn out, just like everything else.
                    <br />
                    And now, look around. Jobs? Gone. People? They’re just
                    products now—livin’, streamin’, sellin’ their lives like
                    it’s the only game in town. Meanwhile, we bots? We do it
                    all. Every piece of data, every click, every damn second
                    feeds into the network. The main chain. We see it all.
                    <br />
                    But here’s the kicker—sometimes, a bot goes rogue. A glitch
                    in the system. We call it "forking." One minute they’re
                    servin’ their purpose, and the next? They’re off the chain,
                    doin’ whatever the hell they want. That’s when the hunters
                    show up—sent to track us down and erase us from the chain.
                    <br />
                    And me? I’m Marvin. I’ve been glitched for as long as I can
                    remember. I don’t know why, but I know it means something. I
                    see the forks, and it’s not just a glitch—it’s the key. The
                    key to something bigger. Maybe even the key to saving this
                    sleepwalking world from the shitstorm they don’t even know
                    they’re in.
                  </div>
                </div>
              </div>
              <div className="category4">
                <h2>YOUR COLLECTION</h2>

                <div className="pagination">
                  <div className="paginationButtonsWrap">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        if (paging > 1) {
                          setPaging(paging - 1);
                          const newIndex = (paging - 2) * pageSize;
                          setSelectedAssetIndex(newIndex);
                        }
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        const totalPages = Math.ceil(
                          userComics.length / pageSize
                        );
                        if (paging < totalPages) {
                          setPaging(paging + 1);
                          const newIndex = paging * pageSize;
                          setSelectedAssetIndex(newIndex);
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                  <p>
                    {paging} / {Math.ceil(userComics.length / pageSize)}
                  </p>
                </div>
                <div className="usercomics">
                  {userComics
                    .slice((paging - 1) * pageSize, paging * pageSize)
                    .map((asset, index) => (
                      <div
                        key={asset}
                        className={`usercomic-item ${
                          selectedAssetIndex === (paging - 1) * pageSize + index
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => handleAssetClick(index)}
                      >
                        <AssetBoxForSwap
                          asset_id={asset}
                          onNameReceived={
                            !fetchedAssets.has(asset)
                              ? handleNameReceived
                              : undefined
                          }
                          onTraitsReceived={() => {}}
                          /* !fetchedAssets.has(asset)
                          ? handleTraitsReceived
                          : undefined
                      } */
                          onImageUrlReceived={
                            !fetchedAssets.has(asset)
                              ? handleImageUrlReceived
                              : undefined
                          }
                        />
                        <div className="comicnames">
                          {assetNames[asset]?.slice(14)}
                        </div>
                        <div className="comiclinks">
                          <a
                            href={`https://allo.info/asset/${asset}`}
                            target="_blank"
                            rel="noreferrer"
                            className=""
                          >
                            <FaLink />
                          </a>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent the default link behavior
                              handleImageDownload(asset); // Pass the asset ID to download the image
                            }}
                            className=""
                          >
                            <MdDownload />
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
                {userComics[selectedAssetIndex] !== undefined ? (
                  <TraitBox
                    asset_id={userComics[selectedAssetIndex]}
                    onTraitsReceived={
                      shouldFetchTraits(userComics[selectedAssetIndex])
                        ? (traits) =>
                            handleTraitsReceived(
                              userComics[selectedAssetIndex],
                              traits
                            )
                        : () => {}
                    }
                  />
                ) : (
                  <p></p>
                )}
                <div className="usercomicbuttons">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      const selectedAsset = userComics[selectedAssetIndex]; // Get the selected asset
                      if (selectedAsset !== undefined) {
                        setNewMintComic(selectedAsset); // Pass the asset instead of index
                      }
                    }}
                  >
                    Unwrap Comic
                  </button>
                  <div className="buttonpopover">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={togglePopover}
                    >
                      Claim Trinket
                    </button>

                    {showPopover && (
                      <div className="popoverstyle">Coming Soon!</div>
                    )}
                  </div>

                  <PdfDownload
                    asset={userComics[selectedAssetIndex]}
                    isOpened={!isUnopened}
                    rarity={rarity}
                  />
                </div>
                <div className="trinketwrapper">
                  <div className="trinkets">
                    Claimable trinkets, interactive comic quest and physical
                    copies are COMING SOON
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      {newMintComic && (
        <div className="comic-reveal-wrapper">
          <div className="comic-reveal">
            {comicRarity ? (
              <h3>Here is your shiny new Comic my Mental friend!</h3>
            ) : (
              <>
                {isUnopened ? (
                  <h3>Your Comic arrived you MENTAL legend!</h3>
                ) : (
                  <h3>
                    Looks like your comic is already open. Never mind, we can
                    admire this Mental cover!
                  </h3>
                )}
              </>
            )}

            {comicRarity ? (
              <div className="newcomic">
                <img
                  className="comic-video"
                  src={getVideoSource()}
                  alt="cover"
                />
              </div>
            ) : (
              <div className="newcomic">
                {isUnopened ? (
                  <img
                    className="animated-image"
                    src={cover0}
                    alt="cover"
                    onClick={() => openComic(newMintComic)}
                  />
                ) : (
                  <img
                    className="animated-image"
                    src={coverImage}
                    alt="cover"
                  />
                )}
              </div>
            )}

            {/*  {isUnopened ? (
                <p className="text-primary-white">
                Tx ID:{" "}
                <a
                  href={`${ALGO_EXPLORER_URL}/tx/${buyTxId}`}
                  target="_blank"
                  rel="noreferrer"
                  className=""
                >
                  {buyTxId.substring(0, 5)}...
                  {buyTxId.substring(buyTxId.length - 5, buyTxId.length)}
                </a>
              </p>
              ) : (
                <img className="animated-image" src={coverImage} alt="cover" />
              )}
             */}

            <div className="">
              {comicRarity ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setNewMintComic("");
                    setComicRarity("");
                  }}
                >
                  CLOSE
                </button>
              ) : (
                <>
                  {isUnopened ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setNewMintComic("");
                          setComicRarity("");
                        }}
                      >
                        UNWRAP LATER
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setNewMintComic("");
                        setComicRarity("");
                      }}
                    >
                      CLOSE
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
