import axios from "axios";
import { useEffect, useState, useRef } from "react";
import algo_logo from "../assets/algo.webp";
import cube_icon from "../assets/cube.webp";
import loadingGIF from "../assets/loading.gif";
import AssetBoxForSwap from "./asset_box_for_swap.tsx";
import { SelectField } from "./select_field.tsx";
import { toast } from "react-toastify";
import algosdk from "algosdk";
import { encode, decode } from "uint8-to-base64";
import { decodeUnsignedTransaction } from "algosdk";
import {
  API_ENDPOINT,
  BASIC_TRAIT_ALGO_FEE,
  BASIC_TRAIT_CUBE_FEE,
  CREATOR_WALLET,
  FULL_MENTAL_MARVIN_RESERVE,
  IPFS_PREFIX,
  NODE_ENDPOINT,
  SPECIAL_TRAIT_ALGO_FEE,
  SPECIAL_TRAIT_CUBE_FEE,
} from "../constants.ts";
import {
  getAssetsFromAddress,
  getBalance,
  trait_shop_check_tx,
  force_cube_optin,
} from "./utilities.ts";
import AssetImage from "./asset_image.tsx";
import "./traitshop.css";
import React from "react";
import { useWallet } from "@txnlab/use-wallet";
import { SpecialTraitBenefitsProps } from "./special_trait_benefits.tsx";
import SwiperCore from "swiper";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import marvin3 from "../assets/marvin/marvin3.webp";
import TraitBox from "./traitbox.tsx";

export type TraitShopTrait = {
  includes(item: TraitShopTrait): unknown;
  id: number;
  trait_type: string;
  trait_name: string;
  ipfs_cid: string;
  description?: string;
};
interface Trait {
  key: string;
  value: string;
}

export default function TraitShop() {
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [connectType, setWalletType] = useState("");
  const [loading, setLoading] = useState(true);
  const [traitData, setTraitData] = useState<Trait[]>([]);
  const [userAssets, setUserAssets] = useState([] as number[]);
  const [userBalance, setUserBalance] = useState([0, 0]);
  const [alreadySpecial, setAlreadySpecial] = useState(false);
  const [selectedMarvin, setSelectedMarvin] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [transactions, setTransactions] = useState([] as any[]);
  const { activeAccount, signTransactions, sendTransactions } = useWallet();
  const [description, setDescription] = useState<any>({});
  const [data, setData] = useState({
    basic: [] as TraitShopTrait[],
    special: [] as TraitShopTrait[],
  });
  SwiperCore.use([Navigation, Pagination]);

  const [newAssetIPFSCID, setNewAssetIPFSCID] = useState("");

  const [selectedBasicTrait, setSelectedBasicTrait] = useState(
    {} as TraitShopTrait
  );
  const [selectedSpecialTrait, setSelectedSpecialTrait] = useState(
    {} as TraitShopTrait
  );
  const [specialTraitBenefits, setSpecialTraitBenefits] = useState<
    SpecialTraitBenefitsProps[]
  >([]);
  const [isSwapButtonClickable, setIsSwapButtonClickable] = useState(false);

  const swiperRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const handleSlideChange = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
      setActiveSlide(index);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAddressLocal = activeAccount?.address;
        const connectTypeLocal = activeAccount?.providerId;

        if (userAddressLocal && connectTypeLocal) {
          setWalletAddress(userAddressLocal);
          setWalletType(connectTypeLocal);
        }
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        setLoading(false);
      }
      const timestamp = Date.now();
      axios
        .get(`${API_ENDPOINT}/trait_shop/?_=${timestamp}`)
        .then((res) => {
          let data = res.data;
          data.basic.sort((a: TraitShopTrait, b: TraitShopTrait) =>
            a.trait_type.localeCompare(b.trait_type)
          );

          setData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, [activeAccount]);

  useEffect(() => {
    if (traitData["Special1"] && traitData["Special1"] !== "none") {
      setAlreadySpecial(true);
    } else {
      setAlreadySpecial(false);
    }
  }, [traitData]);

  useEffect(() => {
    // Update the clickability status whenever relevant conditions change
    setIsSwapButtonClickable(
      selectedMarvin !== "" &&
        (selectedBasicTrait.id !== undefined ||
          selectedSpecialTrait.id !== undefined)
    );
  }, [selectedMarvin, selectedBasicTrait, selectedSpecialTrait]);

  useEffect(() => {
    // Check if selectedSpecialTrait is not null and specialTraitBenefits has data
    if (selectedSpecialTrait && specialTraitBenefits.length > 0) {
      // Find the special trait benefit object with the same trait_name as selectedSpecialTrait
      const matchingBenefit = specialTraitBenefits.find(
        (benefit) => benefit.trait_name === selectedSpecialTrait.trait_name
      );

      // Display the description of the matching special trait benefit
      if (matchingBenefit) {
        setDescription(matchingBenefit);
      } else {
        setDescription(""); // Reset description if no match is found
      }
    }
  }, [selectedSpecialTrait, specialTraitBenefits]);

  useEffect(() => {
    if (walletAddress !== "") {
      getAssetsFromAddress(walletAddress).then((res) => {
        setUserAssets(res);

        async function getSpecialTraitBenefits() {
          const response = await axios.get(
            `${API_ENDPOINT}/special_trait_benefits/`
          );

          setSpecialTraitBenefits(response.data);
        }
        getSpecialTraitBenefits();

        getBalance(walletAddress).then((res) => {
          setUserBalance(res);
          setLoading(false);
        });
      });
    }
  }, [walletAddress]);

  const updateSelectedMarvin = (asset_id: string) => {
    setSelectedMarvin(asset_id.toString());
    if (data.basic.length === 0) {
      axios
        .get(API_ENDPOINT + "/trait_shop/")
        .then((res) => {
          let data = res.data;
          data.basic.sort((a: TraitShopTrait, b: TraitShopTrait) =>
            a.trait_type.localeCompare(b.trait_type)
          );
          console.log(data);
          setData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if select empty, reset all things
    if (asset_id === "") {
      setSelectedBasicTrait({} as TraitShopTrait);
      setSelectedSpecialTrait({} as TraitShopTrait);
    }
  };

  function calculateCost() {
    let algo = 0;
    let fc = 0;
    if (
      selectedBasicTrait.id !== undefined &&
      selectedSpecialTrait.id !== undefined
    ) {
      algo = BASIC_TRAIT_ALGO_FEE + SPECIAL_TRAIT_ALGO_FEE;
      fc = BASIC_TRAIT_CUBE_FEE + SPECIAL_TRAIT_CUBE_FEE;
    } else if (selectedBasicTrait.id !== undefined) {
      algo = BASIC_TRAIT_ALGO_FEE;
      fc = BASIC_TRAIT_CUBE_FEE;
    } else if (selectedSpecialTrait.id !== undefined) {
      algo = SPECIAL_TRAIT_ALGO_FEE;
      fc = SPECIAL_TRAIT_CUBE_FEE;
    }
    return {
      algo: algo,
      fc: fc,
    };
  }

  async function checkShop() {
    if (selectedMarvin === "") {
      toast.info("Please select a Marvin!");
      return;
    }
    if (
      selectedBasicTrait.id === undefined &&
      selectedSpecialTrait.id === undefined
    ) {
      toast.info("Please select at least one trait to swap!");
      return;
    }
    setIsClicked(true);
    try {
      const selected_asset_response = await axios.get(
        `${NODE_ENDPOINT}/v2/assets/${selectedMarvin}`
      );

      if (
        selected_asset_response.data.params.reserve ===
        FULL_MENTAL_MARVIN_RESERVE
      ) {
        toast.error("You can't use the Trait Shop a full mental Marvin!");
        setIsClicked(false);
        return;
      }

      if (selected_asset_response.data.params.creator !== CREATOR_WALLET) {
        toast.error("You selected invalid asset!");
        setIsClicked(false);
        return;
      }

      const rebalance = await getBalance(walletAddress);
      const totalCost = calculateCost();
      setUserBalance(rebalance);
      if (userBalance[0] < totalCost.algo) {
        toast.error("You don't have enough ALGO to pay for the swap fee!");
        setIsClicked(false);
        return;
      }
      if (userBalance[1] < totalCost.fc) {
        toast.error("You don't have enough FC to pay for the swap fee!");
        setIsClicked(false);
        return;
      }

      const transaction = await trait_shop_check_tx(
        walletAddress,
        parseInt(selectedMarvin),
        selectedBasicTrait,
        selectedSpecialTrait
      );

      if (!transaction) {
        toast.error(
          "Something went wrong while creating the check transaction!"
        );
        setIsClicked(false);
        return;
      }

      // Convert algosdk.Tx to Uint8Array
      const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction);

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([encodedTransaction]);

      // Extract signed transaction
      const signedTxn = signedTransactions[0];

      // Encode the signed transaction
      const encodedTxn = encode(signedTxn);

      if (encodedTxn) {
        toast.info("Please wait while checking your request...");
        const res = await axios.post(`${API_ENDPOINT}/trait_shop/`, {
          tx: encodedTxn,
        });
        if (res.status === 200) {
          const transactions = res.data.transactions;
          setNewAssetIPFSCID(res.data.cid);
          if (transactions.length !== 3) {
            toast.error("Something went wrong while creating the swap!");
            setIsClicked(false);
            return;
          }
          setIsClicked(false);
          toast.info("Please sign the transactions to complete the swap!");
          setTransactions(transactions);
        }
      } else {
        toast.error("Something went wrong while creating the swap!");
        setIsClicked(false);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  async function completeShop() {
    if (!transactions || transactions.length !== 3) {
      toast.error("Something went wrong while creating the swap!");
      return;
    }
    setIsClicked(true);
    try {
      let user_algo_payment_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[0], "base64")
      );
      let user_cube_payment_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[1], "base64")
      );
      let selected_asset_config_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[2], "base64")
      );

      let txnsToValidate = [] as Uint8Array[];
      toast.info("Please sign the transactions to complete the swap...");

      const multipleTxnGroups = [
        { txn: user_algo_payment_txn, signers: [walletAddress] },
        { txn: user_cube_payment_txn, signers: [walletAddress] },
        { txn: selected_asset_config_txn, signers: [CREATOR_WALLET] },
      ];

      //flatten the trx
      const transactionsToSign = multipleTxnGroups
        .map(({ txn }) => algosdk.encodeUnsignedTransaction(txn))
        .flat();

      //sign trx
      const signedTransactions = await signTransactions([transactionsToSign]);

      if (!signedTransactions) {
        toast.error("Something went wrong while signing the transactions!");
        setIsClicked(false);
        return;
      }

      txnsToValidate = [
        signedTransactions[0],
        signedTransactions[1],
        decode(transactions[2]),
      ];

      //encode signed trx
      const validateData = txnsToValidate.map((txn) => {
        return encode(txn);
      });

      //this is ok now
      const validate_response = await axios.post(
        `${API_ENDPOINT}/trait_shop/validate/`,
        {
          txns: validateData,
        }
      );
      if (validate_response.status === 200) {
        toast.success("Successfully completed!");
        setIsClicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Something went wrong while confirming the transactions!");
        setIsClicked(false);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  async function FCOptin(walletAddress: string, assetID: number) {
    if (!walletAddress) {
      toast.error("Please connect your wallet first!");
      return;
    }
    setIsClicked(true);
    const transaction = await force_cube_optin(walletAddress, assetID);
    if (!transaction) {
      toast.error("Something went wrong while creating the swap request!");
      setIsClicked(false);
      return;
    }

    try {
      // Sign transaction using useWallet module
      const signedTxn = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction),
      ]);

      const txId = await sendTransactions(signedTxn);
      console.log("Transaction ID:", txId);
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center text-primary-green font-thin">
        <img
          className="aspect-square max-h-48"
          src={loadingGIF}
          alt="loading"
        />
        Loading...
      </div>
    );
  }

  return (
    <div className="limiter">
      <div className="shopmain-wrap">
        <h2 className="section-title section-title--mobile">TRAIT SHOP</h2>
        <div className="shopmain">
          <h2 className="section-title section-title--desktop">TRAIT SHOP</h2>
          <div>
            <div className="shopdescription">
              <p>
                Basic traits can be placed only on <b>empty/none</b> trait
                spots.
                <br></br>
                <b>WARNING</b>
                <br></br>
                Special Traits cannot be removed, only replaced. Replaced
                special traits are BURNED and cannot be reverted.
              </p>
            </div>
            <>
              {newAssetIPFSCID && (
                <div
                  className="modal fade modal-lg show"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title fs-5"
                          id="staticBackdropLabel"
                        >
                          New Marvin Look
                        </h1>
                      </div>
                      <div className="modal-body">
                        <AssetImage
                          image_url={`${IPFS_PREFIX}/${newAssetIPFSCID}`}
                        />
                      </div>

                      <div className="modal-footer">
                        {isClicked ? (
                          <button
                            className="btn btn-info"
                            type="button"
                            disabled
                          >
                            <div
                              className="spinner-border text-light mr-2"
                              role="status"
                            ></div>
                          </button>
                        ) : (
                          <span>
                            Click &nbsp;
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={completeShop}
                            >
                              Complete
                            </button>
                            &nbsp; to apply the new look or
                          </span>
                        )}
                        <span>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              setNewAssetIPFSCID("");
                              setTransactions([]);
                            }}
                          >
                            Reject
                          </button>
                          &nbsp;to try something else.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>{" "}
            <div className="maincontainer">
              {/* marvin selector */}
              <div className="leftpart">
                <div className="shopselect">
                  <SelectField
                    text="a Marvin"
                    selectedAsset={selectedMarvin}
                    setSelectedAsset={updateSelectedMarvin}
                    assets={userAssets}
                  />
                  <div className="imagestretch">
                    {selectedMarvin !== "" ? (
                      <div className="swapperimage">
                        <AssetBoxForSwap
                          asset_id={selectedMarvin}
                          onNameReceived={undefined}
                          onTraitsReceived={undefined}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="swapperimage">
                          <img src={marvin3} alt="marvin" className="marvin3" />
                        </div>
                      </div>
                    )}
                    <div className="traits1">
                      {selectedMarvin !== "" ? (
                        <TraitBox
                          asset_id={selectedMarvin}
                          onTraitsReceived={setTraitData}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                </div>

                {/* cost info */}
                <div className="shopcost">
                  <h2>COST</h2>
                  <div className="costmobile">
                    <div className="priceshow">
                      <div className="algo">
                        <img src={algo_logo} alt="algo" />

                        {calculateCost().algo}
                      </div>

                      <div className="fccube">
                        <img src={cube_icon} alt="cube" />

                        {calculateCost().fc}
                      </div>
                    </div>

                    {isClicked ? (
                      <button className="btn btn-info" type="button" disabled>
                        <div
                          className="spinner-border text-light mr-2"
                          role="status"
                        ></div>
                      </button>
                    ) : (
                      <>
                        {transactions.length !== 3 ? (
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={checkShop}
                            disabled={!isSwapButtonClickable}
                          >
                            CHECK
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-info"
                            disabled
                          >
                            Complete
                          </button>
                        )}
                        {/* <p className="text-primary-white/80 text-xs text-center mt-1">
                          Process consists of two steps.
                        </p> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* trait selector */}
              <div className="rightpart">
                <h3 className="section-title section-title--mobile">
                  BASIC TRAITS
                </h3>
                <div className="traitselector">
                  <div className="basictraits">
                    <h3 className="section-title section-title--desktop">
                      BASIC TRAITS
                    </h3>
                    <div className="basic-trait-buttons">
                      <button
                        className={`btn btn-info ${
                          activeSlide === 0 ? "active" : ""
                        }`}
                        onClick={() => handleSlideChange(0)}
                      >
                        CLOTHES
                      </button>
                      <button
                        className={`btn btn-info ${
                          activeSlide === 1 ? "active" : ""
                        }`}
                        onClick={() => handleSlideChange(1)}
                      >
                        HAIR
                      </button>
                      <button
                        className={`btn btn-info ${
                          activeSlide === 2 ? "active" : ""
                        }`}
                        onClick={() => handleSlideChange(2)}
                      >
                        HAND
                      </button>
                    </div>
                    <div className="">
                      <MdKeyboardDoubleArrowLeft />
                      SWIPE OR CLICK THE BUTTON
                      <MdKeyboardDoubleArrowRight />
                    </div>
                    <Swiper
                      className="traitswiper"
                      slidesPerView={1}
                      ref={swiperRef}
                      onSlideChange={(swiper) =>
                        setActiveSlide(swiper.activeIndex)
                      }
                      navigation={false}
                    >
                      <SwiperSlide>
                        <ul className="trait">
                          {data.basic.map((item) => {
                            // Check if the item's trait_type is "clothes"
                            if (item.trait_type === "Clothes") {
                              return (
                                <div
                                  key={item.id}
                                  className={`traititem ${
                                    selectedBasicTrait.id === item.id
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  <img
                                    key={item.id}
                                    src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                                    alt={item.trait_name}
                                    onClick={() => {
                                      if (item.id === selectedBasicTrait.id) {
                                        setSelectedBasicTrait(
                                          {} as TraitShopTrait
                                        );
                                        return;
                                      }
                                      setSelectedBasicTrait(item);
                                    }}
                                  />
                                  <h5>{item.trait_name}</h5>
                                </div>
                              );
                            }
                            // Return null if the item's trait_type is not "clothes"
                            return null;
                          })}
                        </ul>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ul className="trait">
                          {data.basic.map((item) => {
                            // Check if the item's trait_type is "clothes"
                            if (item.trait_type === "Hair") {
                              return (
                                <div
                                  key={item.id}
                                  className={`traititem ${
                                    selectedBasicTrait.id === item.id
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  <img
                                    key={item.id}
                                    src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                                    alt={item.trait_name}
                                    onClick={() => {
                                      if (item.id === selectedBasicTrait.id) {
                                        setSelectedBasicTrait(
                                          {} as TraitShopTrait
                                        );
                                        return;
                                      }
                                      setSelectedBasicTrait(item);
                                    }}
                                  />
                                  <h5>{item.trait_name}</h5>
                                </div>
                              );
                            }
                            // Return null if the item's trait_type is not "clothes"
                            return null;
                          })}
                        </ul>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ul className="trait">
                          {data.basic.map((item) => {
                            // Check if the item's trait_type is "clothes"
                            if (item.trait_type === "Hand") {
                              return (
                                <div
                                  key={item.id}
                                  className={`traititem ${
                                    selectedBasicTrait.id === item.id
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  <img
                                    key={item.id}
                                    src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                                    alt={item.trait_name}
                                    onClick={() => {
                                      if (item.id === selectedBasicTrait.id) {
                                        setSelectedBasicTrait(
                                          {} as TraitShopTrait
                                        );
                                        return;
                                      }
                                      setSelectedBasicTrait(item);
                                    }}
                                  />
                                  <h5>{item.trait_name}</h5>
                                </div>
                              );
                            }
                            // Return null if the item's trait_type is not "clothes"
                            return null;
                          })}
                        </ul>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <h3 className="section-title section-title--mobile">
                    SPECIAL TRAITS
                  </h3>
                  <div className="specialtraits">
                    <h3 className="section-title section-title--desktop">
                      SPECIAL TRAITS
                    </h3>
                    <div className="trait">
                      {data.special.map((item, _i) => (
                        <div
                          key={item.id}
                          className={`traititem ${
                            selectedSpecialTrait.id === item.id
                              ? "selected"
                              : ""
                          }`}
                        >
                          <img
                            className={
                              "w-24 h-24 border-2 rounded-xl" +
                              (item.id !== selectedSpecialTrait.id
                                ? " grayscale"
                                : " border-primary-green")
                            }
                            key={item.id}
                            src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                            alt={item.trait_name}
                            onClick={() => {
                              if (item.id === selectedSpecialTrait.id) {
                                setSelectedSpecialTrait({} as TraitShopTrait);
                                return;
                              }
                              setSelectedSpecialTrait(item);
                              calculateCost();
                            }}
                          />
                          <h5>{item.trait_name}</h5>
                        </div>
                      ))}
                    </div>
                    <div className="specialwarning">
                      {alreadySpecial && (
                        <>
                          <h5>WARNING!!</h5>
                          <p>
                            Selected Marvin already has a Specail trait.
                            <br />
                            Putting a new Special will replace the current one.
                          </p>
                        </>
                      )}
                    </div>

                    <div className="copytoken-wrap">
                      <h6>BENEFITS</h6>
                      <div className="copytokenid">
                        {selectedSpecialTrait.trait_name ? (
                          description && Object.keys(description).length > 0 ? (
                            <>
                              <div className="copytoken-description">
                                {" "}
                                {description.description}
                              </div>
                              <div className="coin-wrap">
                                <img
                                  className="coin"
                                  src={
                                    IPFS_PREFIX + "/" + description.token_image
                                  }
                                  alt={`${description.token_name}_image`}
                                />

                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                  onClick={() =>
                                    FCOptin(walletAddress, description.token_id)
                                  } // Call FCOptin with walletAddress and description.token_id
                                  disabled={isClicked}
                                >
                                  {isClicked ? "Processing..." : "OPT-IN"}
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="copytoken-nodescription">
                              No current benefits.
                            </div>
                          )
                        ) : (
                          <div className="copytoken-nodescription">
                            Select Special Trait
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="description">
                  {" "}
                  <div className="text-center text-white/50 text-xs mt-1">
                    <div className="animate-pulse text-primary-white text-red-500">
                      <h5>Special Trait Requirement</h5>
                    </div>
                    {selectedSpecialTrait.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
