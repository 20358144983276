// Import necessary libraries and components

import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "bootstrap";
import React from "react";
import maars from "../assets/maars.webp";
import blessed from "../assets/blessed.webp";
import oshi from "../assets/oshi.webp";
import prezident from "../assets/prezident.webp";
import bykewl from "../assets/BK.webp";

// Main component function
export default function About() {
  useEffect(() => {
    const accordion = document.getElementById("accordionFlushExample");

    // Add event listener for Bootstrap collapse events
    accordion.addEventListener("show.bs.collapse", function (event) {
      const currentlyOpen = accordion.querySelector(".show");
      if (currentlyOpen && currentlyOpen !== event.target) {
        // Close the currently open item if it's not the one being opened
        new Collapse(currentlyOpen).hide(); // Use Collapse from Bootstrap package
      }
    });
  }, []);

  return (
    <div className="burncontainer">
      <div className="burnNFT">
        <h2>ABOUT</h2>

        <>
          <div className="closeposition">
            <Link
              to="/"
              type="button"
              className="btn-close"
              aria-label="Close"
            ></Link>
          </div>

          <div className="thestory">
            <div className="aboutbox">
              <h3>The Team</h3>
              <div className="teambox">
                <div className="member">
                  <h4>Maars</h4>

                  <img src={maars} alt="maars"></img>
                  <p>Project Owner, Artist and Mastermind</p>
                </div>
                <div className="member">
                  <h4>Blessed4aDay</h4>
                  <img src={blessed} alt="blessed"></img>
                  <p>Comic book advisor and discord Hobby Shop lead</p>
                </div>
                <div className="member">
                  <h4>Bykewel</h4>
                  <img src={bykewl} alt="bykewl"></img>
                  <p>Full stack dev</p>
                </div>
                <div className="member">
                  <h4>Oshi</h4>
                  <img src={oshi} alt="oshi"></img>
                  <p>OG*</p>
                </div>
                <div className="member">
                  <h4>Prezident</h4>
                  <img src={prezident} alt="prezident"></img>
                  <p>OG*</p>
                </div>
              </div>
              *OG - a supportive volontairy role. Provides feeback, community
              support and project info to new users.
            </div>
            <p></p>
            <h3>Everything you need to know about the project</h3>

            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h4 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    How it started
                  </button>
                </h4>

                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Mental Marvin began as a project called Maars Comics in
                    2021. It featured a series of one-shot comic book panels on
                    the blockchain, depicting intriguing events within the
                    community. Over a few months, the first characters were
                    born, and a story started to take shape. Kevin, Franky,
                    Marvin, Kate, Ripley, and others emerged as ideas in the
                    comic book panels and later became digital collectibles in
                    2022 and 2023. The projact still aims to deliver top quality
                    story driven collectables, comics and interactive mini game
                    content.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    The Mini Games
                  </button>
                </h4>

                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Exploring new technologies, we developed our first
                    interactive comic website. On this site, you could play
                    mini-games that followed the comic book story, spanning
                    across 10 interactive mini-games where you could win rewards
                    and collect items. This led to the birth of a new
                    interactive digital storytelling experience, merging various
                    elements to tell a compelling story utilizing the latest
                    technologies.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    Force Cubes
                  </button>
                </h4>

                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Following the success of the games and the first fully
                    custom collection The Force Cube was introduced as important
                    item in the overall story that doubles as a form of project
                    currency, which you can trade for additional items and
                    upgrades. It's a link between the two worlds.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    The Comic Book
                  </button>
                </h4>

                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      While all the digital content was exciting, the heart of
                      the project lay in the main story. A dedicated effort was
                      made to write a comprehensive narrative focusing on our
                      key characters—Marvin, Franky, and Ripley. After a year
                      and a half of writing, collaboration, and brainstorming,
                      the story was drafted into three chapters, each consisting
                      of 3-4 comic book issues.
                    </p>
                    <p>
                      The story blends near-real sci-fi elements with comedy,
                      drama, and action. It addresses contemporary topics like
                      AI, blockchain, data privacy, and the dangers of
                      extremism, presented in an engaging way through themes of
                      friendship, family, love, and real-life situations
                      inspired by the real world.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="flush-headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    The Mental Marvin Collection
                  </button>
                </h4>

                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Alongside exploring this new world through the story, we
                    created a primary digital collection that became the
                    project's main branding: Mental Marvin. This collection
                    features Marvin, the central figure in all the events (no
                    spoilers!). The collection offers a novel approach to
                    interacting with digital collectibles, allowing full
                    customization and personalization while mentaining full
                    ownership, and enabling users to collect new items through
                    interactive content.
                    <p>
                      The collection is designed so that its assets can be
                      utilized in various forms of content, including games,
                      while allowing the user to retain full ownership and
                      control.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="flush-headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    Community and Collaboration opportunities
                  </button>
                </h4>

                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    The project opens up numerous collaboration opportunities,
                    allowing outside projects or collections to eather be
                    featured in the comic, have some of their assets or utility
                    linked to the interactive web contant or collaborate on
                    Mental Marvin traits for benfit of both parties. While this
                    isn't entirely new, the use of blockchain for asset
                    ownership and verification simplifies incorporating other
                    digital collections and systems, promoting them, rewarding
                    users, and enriching the community. This innovative
                    collaboration approach, where many can gain exposure and
                    benefit from a collective effort in a trustless manner is at
                    the core of our project.
                  </div>
                  <p>Current tried and tested way to collaborate:</p>
                  <li>
                    Apply your art to be incorporated as character or item in
                    the comic while giving you credit an link to your project
                    for our users.
                  </li>
                  <li>
                    Have your digital asset (non liquid) be a part of reward,
                    quest or a mini game.
                  </li>
                  <li>
                    Apply for a Special Marvin trait where users can customize
                    their Marvins with items from your project adding benefits
                    to both sides and giving exposure to your project.
                  </li>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="flush-headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSeven"
                  >
                    The Future
                  </button>
                </h4>

                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    The primary goal is to tell the story, embrace new
                    technology, and have fun while building new, exciting things
                    and expanding the community. If you love wild, imaginative
                    stories, lively and creative communities, or simply great
                    art, this is the place for you.
                  </div>
                </div>
              </div>
            </div>

            <div className="aboutbox">
              <h3>Disclaimer</h3>
              All assets hold no intrinsic value beyond their built-in utility
              within the system. Ownership of a digital asset does not guarantee
              any profit and their value is subjective, determined by the
              personal desirability to the user.
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
